.services {
  a.services {
    margin-top: 2rem;
    text-decoration: none;
    color: inherit;
  }
  .links-services > .row + .row {
    margin-top: @padding-large-vertical*3;
  }
  // .services-pics-hover {
  //   position: absolute;
  //   width: 100%;
  //   top: 0;
  //   bottom: 0;
  // }
  .services .services-pics:after {
    content: '';
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    background-color: rgba(255, 255, 255, 0.46);
    border-radius: 50%;
    transition: opacity 50ms ease-out;
  }
  .services .services-pics:after {opacity: 0;}
  .services:hover .services-pics:after {opacity: 1;}

  .services-pics,
  .services-text {
    height: 100px;
  }
  .services-pics {
    border: 5px solid transparent;
    border-radius: 50%;
    transition: border 50ms ease-out;
    background-repeat: no-repeat;
    width: 100px;
    background-size: auto;
    background-position: center center;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: @screen-md-min) {
      margin-left: -5px;
      margin-right: 0;
    }
    overflow: hidden;
    position: relative;
  }
  .services:hover .services-pics {
    border: 5px solid @brand-yellow;
  }
  .services.active .services-pics {
    border: 5px solid @brand-yellow;
  }

  .col-md-6 nopadding {
    padding-left: 0;
  }
  .services-text {
    text-transform: uppercase;
    font-size: @font-size-base;
    font-family: @font-family-serif;
    vertical-align: middle;
    margin: 0;

    p {
      line-height: 1;
      margin: 2rem 0 0 0;
    }

    @media screen and (min-width: @screen-md-min) {
      display: table-cell;

      p {
        margin: 0;
      }
    }
  }
  .title-services-second {
    font-family: @font-family-sans-serif;
    font-size: 2.2rem;
    font-weight: lighter;
    text-transform: uppercase;
    padding-top: @padding-large-vertical*4;
  }
  .hr-yellow {
    margin:0;
    padding: 0;
    border:4px solid @brand-yellow;
    position: relative;
  }
  .hr-yellow:before {
    content: "";
    display: block;
    position: absolute;
    right: 100%;
    left: -1000%;
    top: -4px;
    bottom: 0;
    border: 4px solid @brand-yellow;
  }
  .hr-yellow:after{
    content: "";
    display: block;
    position: absolute;
    left: 100%;
    right: -1000%;
    top: -4px;
    bottom: 0;
    border: 4px solid @brand-yellow;
  }
  span.glyphicon.glyphicon-triangle-bottom{
    position: relative;
    top: -13px;
    font-size: 4.8rem;
    color: @brand-yellow;
  }
  .bold-text-services {
    font-size: 1.6rem;
    font-weight: bold;
    padding-bottom: @padding-large-vertical*6;
    font-family: @font-family-sans-serif;
  }
  .normal-text-services {
    padding-bottom: @padding-large-vertical*21;
    font-size: 1.6rem;
    // padding-right: 21px;
    font-family: @font-family-sans-serif;
  }
  .p-text-services + .p-text-services {
    margin-top: @padding-large-vertical*3;
  }
  .img-text-services{
    width: 35%;
    float: right;
    margin: 0 0 0 @padding-large-vertical*2;
  }
}

.services {
  .services-text {
    @media screen and (max-width: @screen-md-min) {
      text-align: center;
    }
  }
}
