.testimonials {
  .testimonials-list {
    .testimonial-image {
      header {
        img {
          @media screen and (max-width: @screen-sm-max) {
            .center-block;
            margin-bottom: 2rem;
          }
        }
      }
    }
    blockquote:before { content: unset; }
    .testimonial-quote:before {
      display: block;
      content: "\"";
      position: relative;
      top: -1rem;
      font-size: 6rem;
      line-height: 1;
      max-height: @blockquote-font-size;
      font-family: serif;
      font-style: italic;
    }
    .testimonial-quote.text-right:before {
      right: -1rem;
      float: right;
    }
    .testimonial-quote:not(.text-right):before {
      left: -1rem;
      float: left;
    }
    hr {
      border-color: #ccc;
    }
  }
}