.btn-own {
  margin-top: @padding-large-vertical*3;
  border-radius: 0;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.33);
  padding: 0 @padding-large-horizontal*2.8125;
  line-height: @line-height-computed*2.5;

  &.btn-lg {
    .button-size(@padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @btn-border-radius-large);
  }
  &.btn-sm {
    .button-size(@padding-base-vertical; @padding-small-horizontal; @font-size-base*0.714285714285714; @line-height-small; @btn-border-radius-small);
  }
  &.btn-xs {
    .button-size(@padding-xs-vertical; @padding-xs-horizontal; @font-size-base*0.75; @line-height-small; @btn-border-radius-small);
  }
}

.btn-own.norm {
  font-size: @font-size-base+1;
}
.btn.wide {
  padding-left: @padding-large-horizontal*9;
  padding-right: @padding-large-horizontal*9;
}
.btn.flat {box-shadow: none;}
.btn.sharp {border-radius: 0;}
.btn-own.inline {
  display: inline-block;
}

.btn-yellow-pos,
.btn-yellow-neg,
.btn-trans-pos,
.btn-trans-neg {
  .btn-own;

  &:not([class*="btn-xs"]):not([class*="btn-sm"]):not([class*="btn-lg"]) {
    .btn-own.norm;
  }

  &:not([class*="btn-block"]) {
    .btn-own.inline;
  }
}

.btn-yellow {
  .button-variant(@brand-white; @brand-yellow; darken(@brand-yellow, 5%));
}

.btn-yellow-neg {
  .button-variant(@brand-white; @brand-yellow; darken(@brand-yellow, 5%));
}

.btn-yellow-pos {
  .button-variant(@gray; @brand-yellow; darken(@brand-yellow, 5%));
}

.btn-trans-neg {
  .button-variant(@brand-yellow; transparent; @brand-yellow);

  &:hover {
    //background-color: rgba(255,255,255,.05);
    border-color: darken(@brand-yellow, 15%);
    color: lighten(@brand-yellow, 15%);
    //box-shadow: 0 4px 10px 6px rgba(0,0,0,0.33);
    //transform: scale(1.025);
  }
}

.btn-trans-pos {
  .button-variant(@gray; transparent; @gray);

  &:hover {
    background-color: @brand-white;
    border-color: darken(@gray, 20%);
  }
}

.suggest-property {
  top: 115px;
  right: 0;
  position: fixed;
  padding: 0 @padding-small-horizontal*5;
  background: @brand-info;
  border-top-left-radius: @border-radius-base*20;
  border-bottom-left-radius: @border-radius-base*20;
  display: inline-block;
  font-weight: bold;
  font-size: @font-size-h4;
  text-transform: uppercase;
  text-decoration: none !important;
  line-height: @line-height-computed*3;
  color: @brand-white !important;
  z-index: 9;
  box-shadow: 0px 4px 21px 0px rgba(0,0,0,0.17);
  transition: all .2s ease-in-out;

  &:hover {
    padding-right:@padding-small-horizontal*10;
    background: @brand-yellow;
  }

  @media screen and (max-width: @screen-sm-max) {
    transform: rotate(-90deg) translateY(-50%) scale(.6);
    border-top-left-radius: @border-radius-base*2;
    border-bottom-left-radius: 0;
    border-top-right-radius: @border-radius-base*2;
    transform-origin: right;
    top: 30%;
    right: -@line-height-computed;

    &:hover {
      padding-right:@padding-small-horizontal*5;
      right: 0;
    }
  }
}

@media screen and (max-width: @screen-xs-max) {
  // big ugly override
  // .suggest-property {
  //   position: absolute;
  //   -webkit-transform: unset;
  //   -ms-transform: unset;
  //   transform: unset;
  //   border-top-left-radius: 0;
  //   border-top-right-radius: 0;
  //   top: 73px;
  //   left: 0;
  //   right: 80px;
  //   z-index: 999999;
  //   font-size: 2rem;
  //   line-height: initial;
  //   padding: 10px 20px;
  // }
}

// back to top
.btn.back-to-top {
  position: fixed;
  z-index: 999999;
  bottom: 8em;
  right: 10rem;
  text-decoration: none;
  border-radius: 50%;
  width: @padding-small-horizontal*4.5;
  line-height: @padding-small-horizontal*4.5;
  height: @padding-small-horizontal*4.5;
  font-size: 3rem;
  padding: 0;
  display: none;

  @media screen and (max-width: @screen-sm-max) {
    left: 1.5rem;
    bottom: 2rem;
  }
}
