body {overflow-x: hidden;} // bye bye horizontal scrollbar
body {background-image: url('/img/top-viewed-bg.jpg');}

// dynamic vertical centering
// https://css-tricks.com/centering-css-complete-guide/
.abs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-wrapper {
  position: relative;

  .center {
    .abs;
    //overflow: auto;
    //margin: auto;
  }
}

// make sure the checkboxes use glyphs font (legacy issue)
.bootstrap-checkbox .icon { .glyphicon; }

// button transitions
.btn {transition: all 150ms ease-out;}

// no selection outline
// http://stackoverflow.com/a/4407335/4876586
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* IE/Edge */
  user-select: none;           /* non-prefixed version, currently not supported by any browser */
}

// headings
.section-heading {
  margin-bottom: @padding-large-vertical*6.5;
  padding-bottom: @padding-large-vertical*3;
  background: url('/img/title-underline.png') no-repeat center bottom;
  font-family: @font-family-headings;
  font-size: @font-size-base*3;
  font-weight: 300;
  text-transform: uppercase;

  @media screen and (max-width: @screen-sm-max) {
    font-size: 2.4rem;
  }
}

.section-heading.small { font-size: @font-size-base*2.28571428571429; }

.section-heading.dark { color: @brand-gray; }
.section-heading.light { color: @brand-yellow; }

.page-title {
  font-weight: bold;
  font-size: @font-size-base*4.64285714285714;
  color: @brand-white;
  text-transform: uppercase;
  text-shadow: 2px 4px 10px rgba(0, 0, 0, 0.33);
}

// breadcrumbs
.breadcrumb {
  background-color: transparent;
  border: 0;
  font-size: @font-size-small;
  padding-left: 0;
  padding-right: 0;
  margin-top: @padding-large-vertical*2;
  margin-bottom: @padding-large-vertical*2;

  li {
    a {
      color: lighten(@gray-light, 10%);
    }
    &.active {
      color: @gray-dark;
    }
  }
}

// labels
.label.yellow-pos {.label-variant(@brand-yellow)}

// img thumbnail
.img-yellow-pos {
  .thumbnail;
  padding: 0;
  background-color: @brand-yellow;
  border: 5px solid @brand-yellow;
}

// no rounded corners
.sharp {border-radius: 0;}

.socials {
  li {
    vertical-align: middle;
  }
  #vk_like {
    &, iframe {width: 90px !important;}
  }
  #ok_shareWidget,
  .fb-share-button { height: 20px; }
  .printfriendly img {
    vertical-align: -5px !important;
  }
  .fb_iframe_widget span {
    vertical-align: -4px !important;
  }
}

.prices {
  background:@brand-yellow;

  .price-desc,
  .price {
    color: @gray-dark;
    margin: 0;
  }
  .price {
    font-weight: bold;
    font-size: @font-size-base*1.42857142857143;
    line-height: 1;
    margin-top: 0.6rem;

    .currency {
      display: block;
      font-size: 60%;
    }
  }
}

[class*="col-"].nopadding {
  padding-left: 0;
  padding-right: 0;
}

[class*="col-"].nopadding-left {
  padding-left: 0;
}

[class*="col-"].nopadding-right {
  padding-right: 0;
}

blockquote footer {
  margin-top: 1rem;
}

blockquote:before {
  display: block;
  content: "\"";
  font-size: 6rem;
  position: absolute;
  font-family: serif;
  font-style: italic;
  top: -1rem;
}
.text-right blockquote:before {
  right: 0;
}
:not(.text-right) blockquote:before {
  left: 0;
}