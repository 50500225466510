.results {
  .image-wrapper {
    max-height: 184px;
    max-height: 148px;
    overflow-y: hidden;
  }
  .prices {
    background:@brand-yellow;
    padding-top: @padding-large-vertical*2;
    padding-bottom: @padding-large-vertical*2;

    .price-desc,
    .price { margin: 0; }
    .price {
      font-weight: bold;
      font-size: @font-size-base*1.42857142857143;
    }
  }
}
