.top-viewed {
  ul {
    .clearfix;

    li {
      display: inline-block;
      float: left;
      width: 50%;

      &:first-child {text-align: left;}
      &:last-child {text-align: right;}
    }
  }
  .prices {
    padding-top: 1rem;
    padding-bottom: 0;
    border-top: 1px solid lighten(@brand-yellow, 20%);

    .price {
      margin-top: 0.2rem;

      .value {}

      .currency {
        display: block;
        font-size: 60%;
      }
    }
  }
}
