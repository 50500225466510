.filmstrip-hero {
  margin-bottom: 0;

  .center {
    min-width: 80%;
    padding-bottom: @filmstrip-nav-height+@padding-small-vertical*2+@padding-large-vertical*1.6;
  }
  .slick-slide {
    height: 500px;

    .b-lazy { max-width: unset; }
  }
  .slide-title {
    .page-title;
    font-size: @font-size-base*3.57142857142857;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.filmstrip-nav {
  background-color: rgba(red(@brand-gray), green(@brand-gray), blue(@brand-gray), .68);
  position: absolute;
  bottom: 0; left: 0; right: 0;
  padding-top: @padding-large-vertical*0.8;
  padding-bottom: @padding-large-vertical*0.8;

  .filmstrip-control {
    margin-bottom: 0;

    .slick-slide {
      margin-right: @padding-base-horizontal;
      padding: @padding-small-vertical;
      box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.17);
      cursor: pointer;

      img {
        width: @filmstrip-nav-width;
        height: @filmstrip-nav-height;
        outline: @padding-small-vertical solid white;
      }
    }
  }

  .slick-arrow {
    &, img {width:35px; height: 52px;}
  }

  .slick-prev {
    left: -35px;
  }

  .slick-next {
    right: -35px;
  }
}
