.cover {
  .main-title {
    font-weight: bold;
    font-size: @font-size-base*4.64285714285714;
    color: @brand-white;
    text-transform: uppercase;
    text-shadow: 2px 4px 10px rgba(0, 0, 0, 0.33);
  }
  @media screen and (max-width: @screen-sm-min) {
    .main-title {
      font-size: @font-size-large;
      text-align: center;
      line-height: 1;
      margin: 0;
    }
  }
}
