.offer {
  .checkbox-inline.text-left {
    text-align: left;
    padding-left: @padding-small-horizontal*4;
  }
  .offer-form {
    padding-bottom: @padding-large-vertical*6;

    .row + .row {
      margin-top: @padding-large-vertical*9;
    }
    .form-control {
      .sharp;
    }
    .form-group-lg .checkbox-list {
      font-size: @font-size-large;
    }
    [class*="checkbox"] + [class*="checkbox"] {
      margin-left: 0;
    }
    [class*="checkbox"].control-label {
      text-align: left;
      margin-left: 2rem;
      font-weight: normal;

      input {
        margin-top: 7px;
      }
    }
    [type="file"] {
      padding: 0px;
      border: 0px none;
      outline: 1px solid @input-border;
      opacity: 0;


      &#archFileId {
        position: absolute;
        top: 0;
        padding-right: @grid-gutter-width;
        cursor: pointer;
      }
    }
    .uploader {
      /* @media screen and (max-width: @screen-sm-max) {
        margin-bottom: @padding-large-vertical*4;
      } */
      margin-top: @padding-large-vertical*4;

      &.main .square,
      &.extra .square {
        text-align: center;
        background-color: white;
        width: @padding-small-horizontal*13;
        height: @padding-small-horizontal*13;
        border: 1px dashed #cccbcb;
        display: block;
        position: relative;
        overflow: hidden;

        [type="file"] {
          height: 100%;
          opacity: 0;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          cursor: pointer;
        }
        .icon {
          font-size: ceil(@font-size-base*@line-height-base*2.57142857142857);
          color: white;
          background-color: @brand-success;
          border-radius: 50%;
          display: inline-block;
          line-height: 2.57142857142857;
          width: @padding-small-horizontal*5.2;
          height: @padding-small-horizontal*5.2;
          text-align: center;
          border: 7px solid @gray-lighter;
          margin-top: @padding-large-vertical*3.9;

          span {
            margin-top: -49px;
            display: block;
          }
        }
        .preview {
          position: absolute;
          top: 0;
          left: 0;
          height: auto;
          margin-left: 50%;
          transform: translateX(-50%);
          max-height: 100%;

          + .glyphicon-remove {
            color: white;
            border: 2px solid white;
            border-radius: 50%;
            padding: @padding-base-horizontal/3;
            box-shadow: 0px 0px 3px black;
            text-shadow: 0px 0px 2px black;
            background-color: @brand-danger;
            position: absolute;
            right: 6px;
            top: 6px;
            cursor: pointer;
            opacity: .6;
            transition: all 150ms ease;
          }
        }
        &:hover .glyphicon-remove {opacity: 1;}
      }
      p {
        position: absolute;
        bottom: 100%;
        width: 100%;
        padding: 0 @grid-gutter-width/2;
        left: 0;
        text-align: center;
        text-transform: uppercase;
        color: @brand-success;
        font-weight: bold;

        @media screen and (max-width: @screen-sm-max) {
          position: relative;
          text-align: left;
          padding-left: 0;
        }
      }
      &.extra {
        p {
          color: @brand-yellow;
        }
        .square .icon {
          background-color: @brand-yellow;
          border-color: @gray-lighter;
        }
      }
    }
    .btn {
      margin-top: 0;
      line-height: @input-height-large;

      @media screen and (max-width: @screen-sm-max) {
        .btn-block;
      }
    }
  }
}
