.property-wrapper,
.estate-gallery-wrapper {
  position: relative;
}

.ribbon(@bgcolor, @textcolor: white) {
  position: absolute;
  left: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;

  .estate-gallery-wrapper & {
    transform-origin: top left;
    transform: scale(2);
    left: -9px; top: -9px;
  }
  span {
    font-size: 10px;
    font-weight: bold;
    color: @textcolor;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    background: @bgcolor;
    background: linear-gradient(@bgcolor 0%, @bgcolor 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px; left: -21px;

    &:before {
      content: "";
      position: absolute; left: 0px; top: 100%;
      z-index: -1;
      border-left: 3px solid darken(@bgcolor, 20%);
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-top: 3px solid darken(@bgcolor, 20%);
    }

    &:after {
      content: "";
      position: absolute; right: 0px; top: 100%;
      z-index: -1;
      border-left: 3px solid transparent;
      border-right: 3px solid darken(@bgcolor, 20%);
      border-bottom: 3px solid transparent;
      border-top: 3px solid darken(@bgcolor, 20%);
    }
  }
}

.ribbon--orange {
  .ribbon(#F15916);
}

.ribbon--yellow {
  .ribbon(#f1bf29);
}

.ribbon--red {
  .ribbon(#f22a18);
}