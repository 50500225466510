.contact {
  background: url('/img/watermark.png') center center no-repeat, url('/img/top-viewed-bg.jpg');
  background-position: center 60%,center;

  .section-heading {padding-bottom: @padding-base-vertical*3;}

  .contact-data {
    .inline-title {
      font-size: @font-size-base*2.28571428571429;
      font-family: @font-family-serif;
      color: @gray-dark;
      font-weight: bold;
    }
    ul {
      list-style: none;
      padding: 0;
      margin-top: @padding-large-vertical*4;
      margin-bottom: @padding-large-vertical*4;

      li {
        font-size: @font-size-base*1.57142857142857;
        font-family: @font-family-serif;
        font-weight: lighter;
        color: @gray-dark;
        line-height: 2.455;
        &:before {
          transform: translateY(6px);
          margin-right: @padding-small-horizontal*2;
          @media screen and (min-width: @screen-md-min) {
            margin-right: 0;
            margin-left: -@padding-large-horizontal*3;
          }
          display: inline-block;
          float: left;
          text-align: right;
          width: 24px;
        }
        &:nth-of-type(1):before {content: url('/img/icon-contacts-pin.png');}
        &:nth-of-type(2):before {content: url('/img/icon-contacts-phone.png');}
        &:nth-of-type(3):before {content: url('/img/icon-contacts-mail.png');}
        &:nth-of-type(4):before {content: url('/img/icon-contacts-skype.png');}
      }
    }
    p {
      @media screen and (min-width: @screen-md-min) {
        margin-left: -@padding-large-horizontal*3;
      }
      font-size: @font-size-base*1.42857142857143;
      font-family: @font-family-serif;
      color: @gray-dark;
      line-height: 1.5;
    }
  }
  .coordinates {
    text-align: center;

    img {
      margin-bottom: @padding-large-vertical*2.2;
      margin-top: @padding-large-vertical*4;
      margin-left: auto;
      margin-right: auto;
    }
    .btn {
      margin-top: 0;
      margin-bottom: -100%;
      position: relative;
      z-index: 1;
    }

    @media screen and (min-width: @screen-md-min) {
      text-align: center;

      img {
        margin-top: unset;
        margin-left: unset;
        margin-right: unset;
      }
      .btn {float: right;}
    }
  }
  .contact-form {
    form {
      margin-top: @padding-large-vertical*4.6;
      margin-bottom: @padding-large-vertical*11;

      textarea {margin-top: @padding-large-vertical*2;margin-bottom: 0;}
      input, select, textarea {border-radius: 0;}
      [type="submit"] {
        margin-top: @padding-large-vertical*4;
        box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.17);
      }
    }
  }
}
