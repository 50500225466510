.index .top-viewed {
  padding-top: @padding-large-vertical*2;
  padding-bottom: @padding-large-vertical*6;

  @media screen and (min-width: @screen-md-min) {
    padding-top: @padding-large-vertical*13;
    padding-bottom: @padding-large-vertical*7;
  }

  .price-desc,
  .price {
    color: @gray-dark;
  }

  .btn {margin-top: @padding-large-vertical*6;}
}

@media screen and (max-width: @grid-float-breakpoint) {
  .row.cards > [class*="col-"] {
    margin-bottom: @padding-large-vertical*2;
  }
  .row.tiles > [class*="col-"] {
    margin-bottom: @padding-large-vertical*4;
  }
}
