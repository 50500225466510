.top-sales {
  background:@brand-gray;
  padding-top: @padding-large-vertical*7;
  padding-bottom: @padding-large-vertical*5;

  .tile {
    margin-bottom: 0;

    .text-wrapper {
      background-color: @brand-yellow;
      padding: @padding-large-vertical*3;
      &,
      .title,
      ul li {
        color: @gray-dark;
        font-family: @font-family-serif;
      }

      .title {
        font-size: @font-size-base*1.42857142857143;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1.2;
        margin-bottom: @padding-large-vertical*2;
        min-height: unset;
      }
      .description {
        margin-bottom: @padding-large-vertical*2;
        height: floor((@line-height-base*@font-size-base)*3px); // font size * line height * number of lines
      }
      ul {
        text-align: right;
        padding-bottom: 1rem;
        margin-bottom: 0;

        li {
          font-weight: lighter;
          display: inline-block;

          &:first-child {text-align: left;}
          // width: unset;
          // float: none;
          // &:not(:first-child) {margin-left: @padding-small-horizontal*3;}
        }
      }
      .prices {
        padding-top: 1rem;
        padding-bottom: 0;
        border-top: 1px solid lighten(@brand-yellow, 20%);
      }
      .price-heading { margin-bottom: 1rem; }
      .price-body { margin: 0; }
      .price {
        font-weight: lighter;
        font-size: @font-size-base;
        line-height: 1;
        margin-top: 0.2rem;
        margin-bottom: 0;

        .value {
          color: white;
          font-size: @font-size-base*2.42857142857143;
          text-transform: uppercase;
          font-weight: bold;
          margin-left: @padding-small-horizontal*2;
        }

        .currency {
          display: block;
          font-size: 60%;
        }
      }
    }
  }
  .check-all,
  .inline-slider-nav button.slick-arrow {
    margin-top: @padding-large-vertical*5;
    margin-bottom: 0;
  }
}
