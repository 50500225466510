.results {
  .dropdown-toggle {
    margin-top: 0;
    box-shadow: none;

  }
  .search-results {
    padding-top: @padding-large-vertical*2;
    padding-bottom: @padding-large-vertical*2;
    
    .search-params {
      color: @gray-light;
      font-size: @font-size-large;
      font-weight: bold;
      font-family: @font-family-serif;
    }
    .sort {
      color: @gray-light;
      font-size: @font-size-base*0.928571428571429;
      margin-bottom: @padding-base-vertical;
    }
  }
}