.cards {
  .card {
    background: @brand-yellow;
    box-shadow: 0px 4px 21px 0px rgba(0,0,0,0.17);
    display: block;
    text-decoration: none;
    transition: all 200ms ease-out;

    @media screen and (min-width: @grid-float-breakpoint) {
      &:hover {
        transform: scale(1.05);
        z-index: 20;
      }
    }

    .text-wrapper {
      padding: @padding-large-vertical*2.8 @padding-small-horizontal*2.5;

      .title {
        text-transform: uppercase;
        font-size: @font-size-base*1.42857142857143;
        font-family: @font-family-serif;
        font-weight: bold;
        color: @gray-dark;
        margin-top: 0;
        margin-bottom: @padding-large-vertical*2;
        line-height: @padding-large-vertical*2;
        min-height: @padding-large-vertical*6;
      }
      ul {
        list-style-type: none;
        padding-left: 0;
        // margin-bottom: 0;

        li {
          font-weight: 300;
          color: @gray-dark;
        }
      }
    }

    .prices { padding-top: 1rem; }
    .price-desc,
    .price {
      color: white;
    }
  }
}
