.tiles {
  // transparent background
  .tile,
  .tile.trans {
    display: block;
    text-decoration: none;
    transition: all .5s ease-in-out;
    margin-bottom: @padding-large-vertical*6;

    .status {
      position: relative;
      float: right;
      margin-bottom: -100%;
      margin-right: 10px;
      margin-top: -17px;
      line-height: @line-height-computed*3;
      text-transform:uppercase;
      text-align:center;
      font-weight:bold;
      color:white;
      transition: all .5s ease-in-out;

      &.rent { background:@brand-info; }
      &.sale { background:@brand-success;  }

      .rent,
      .sale { line-height: @line-height-computed*1.5; }
    }

    .rent,
    .sale {
      padding-left: @padding-small-horizontal*2;
      padding-right: @padding-small-horizontal*2;
    }
    .rent { background:@brand-info; }
    .sale { background:@brand-success;  }

    .text-wrapper {
      padding:@padding-large-vertical*2.8 0 0 0;

      .title {
        margin: 0 0 @padding-large-vertical*2.5 0;
        min-height: @line-height-computed*4;
        line-height: @line-height-computed;
        color:@brand-yellow;
        font-size: @font-size-h4;
        font-weight: bold;
        color: @brand-yellow;
        text-transform: uppercase;
      }

      ul {
        padding: 0;
        list-style: none;
        margin-bottom: @padding-large-vertical*2;

        li {
          color:lighten(@gray-base, 72%);
          width: 50%;
          float: left;

          &:nth-of-type(2) {text-align:right;}
        }
      }

      .prices {
        background:@brand-yellow;
        padding-top: @padding-large-vertical*2;
        padding-bottom: @padding-large-vertical*2;

        .price-desc,
        .price {
          color: @gray-dark;
          margin-bottom: 0;
        }
        .price {
          font-weight: bold;
          font-size: @font-size-base*1.42857142857143;
        }
      }
    }
    .type {
      position: relative;
      float: right;
      margin-bottom: -100%;
      padding: @padding-large-vertical @grid-gutter-width;
      color: white;
      text-shadow: 1px 1px 2px black;
      font-weight: bold;
      transition: all .15s ease-out;
    }
    @media screen and (min-width: @grid-float-breakpoint) {
      &:hover {
        transform: scale(1.1);
        z-index: 20;
        .status {margin-right: 50px;}
      }
    }
  }

  // white background
  .tile.solid {
    background-color: white;
    box-shadow: 2px 4px 21px 0px rgba(0, 0, 0, 0.17);

    .text-wrapper {
      .title,
      ul {
        padding-left: @padding-base-horizontal*2;
        padding-right: @padding-base-horizontal*2;
      }
    }
    li {
      color: @gray-dark;
    }
    .prices {
      .price-desc,
      .price {
        color: white;
      }
    }
  }

  // in slider
  .slide {
    transition: all .5s ease-in-out;
    //box-shadow: 2px 4px 21px 0px rgba(0, 0, 0, 0.17);
    margin-top: @padding-large-vertical*3;

    .tile.solid {
      //box-shadow: none;

      @media screen and (min-width: @grid-float-breakpoint) {
        &:hover {
          box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.4);
          transform: unset;
          z-index: unset;
          .status {margin-right: unset;}
        }
      }
    }

    @media screen and (min-width: @grid-float-breakpoint) {
      &:hover {
        transform: scale(1.1);
        z-index: 20;
        .status {margin-right: 50px;}
      }
    }
  }

  .btn {margin-top: 0;}
}

.offers-slider {
  .slide {
    .tile {
      box-shadow: none;

      @media screen and (min-width: @grid-float-breakpoint) {
        &:hover {
          transform: unset;
          z-index: unset;
          .status {margin-right: unset;}
        }
      }
    }
    @media screen and (min-width: @grid-float-breakpoint) {
      &:hover {
        transform: unset;
        z-index: unset;
        .status {margin-right: unset;}
      }
    }
  }
}
