.estate {
  font-family: @font-family-serif;

  .jumbo {
    margin-bottom: 0;

    @media screen and (min-width: @screen-lg-min) {
      .property-gallery {
        padding-right: 0;
      }
      .property-info {
        padding-left: 0;
      }
    }
  }
  .inline-title {
    font-size: @font-size-base*2;
    color: @gray-dark;
    margin-top: 0;
  }
  .inline-subtitle {
    margin-top: @padding-base-vertical*2;
    margin-bottom: @padding-large-vertical*3.2;
    font-size: @font-size-base*1.57142857142857;
    color: lighten(@gray-base, 60%);
  }
  .main {
    ul {
      list-style: none;
      padding: 0;

      li {
        font-size: @font-size-largish+1px;
        line-height: 1.765;
        color: @gray;
      }
    }
  }
  .aside {
    position: relative; z-index: 8;

    .metrics,
    .button {
      background-color: white;
      padding-left: @padding-small-horizontal*4;
      padding-right: @padding-small-horizontal*4;
    }

    .metrics {
      padding-top: @padding-large-vertical*5;

      & + .metrics {
          padding-top: 0;
      }

      .flex-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        align-content: stretch;
      }

      .flex-item:nth-child(1) {
        order: 0;
        flex: 0 1 auto;
        align-self: auto;
      }

      .flex-item:nth-child(2) {
        order: 0;
        flex: 0 1 auto;
        align-self: auto;
      }

      .flex-item:nth-child(3) {
        order: 0;
        flex: 0 1 auto;
        align-self: auto;
      }

      .price-body {
        font-family: @font-family-headings;
      }

      .price-heading {
        // font-size: @font-size-base+1;
        color: @gray;
        font-weight: bold;
        margin-top: 0;
      }

      .price-body {
        // text-align: left;
        // font-size: @font-size-base*2.85714285714286;
        font-weight: bolder;
        color: @brand-yellow;
        text-transform: uppercase;

        .suffix {
          font-size: @font-size-large;
          font-weight: normal;
          display: block;
        }
      }

      .right .suffix {text-transform: none;}

      .flex-item:nth-child(3) {
        font-size: @font-size-base+1px;
        color: @gray;
        text-transform: uppercase;
      }
    }
    .button {
      .btn {
        margin: @padding-large-vertical*4.4 auto;
        box-shadow: none;
      }
    }
    .broker {
      background-color: rgba(50, 50, 50, .5);
      padding: 26px 0 27px;
      font-size: @font-size-base+1px;
      color: @gray-dark;

      .contact-title {
        line-height: 1.2;
        font-weight: bold;
        text-transform: uppercase;
        padding-bottom: @padding-base-vertical;
        border-bottom: 1px solid @brand-yellow;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
  .tabbed {
    position: relative; z-index: 9;
    padding: 0;
    background-color: @brand-gray;
    background-image: url('/img/watermark-big.png');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: right -10%;
    padding-top: @padding-large-vertical*5;
    padding-bottom: @padding-large-vertical*9;
  }
  .nav-tabs {
    border-bottom-color: @gray-light;
    border-bottom-width: 3px;
    margin-bottom: @padding-large-vertical*4;

    li {
      color: @brand-white;
      margin-bottom: 0px;
      &, & a {outline: 0 !important;}

      a {
        font-size: @font-size-largish;
        font-family: @font-family-sans-serif;
        color: @brand-white !important;
        font-weight: bold;
        line-height: 1;
        text-transform: uppercase;
        background-color: @brand-yellow;
        border-radius: @border-radius-small @border-radius-small 0 0;
        padding: @padding-large-vertical*1.8;

        &:hover {
          border-color: @brand-yellow @brand-yellow @brand-yellow;
        }
      }

      &.active > a,
      &.active > a:hover,
      &.active > a:focus {
        background-color: darken(@brand-yellow, 10%);
      }
    }
  }
  .tab-content {
    font-size: @font-size-largish+1;
    line-height: 1.765;

    &,a {color: rgba(255, 255, 255, 0.7);}
  }
  .extras-title {
    font-size: @font-size-largish;
    color: @gray;
    font-weight: bold;
    margin-bottom: @padding-large-vertical*2;
  }
  .extras {
    margin-top: @padding-large-vertical*3.5;

    .extra {
      display: inline-block;
      margin-bottom: @padding-large-vertical;
      color: @gray-light;
      font-size: @font-size-smallish;
      font-weight: bold;
      text-decoration: none !important;
      text-transform: uppercase;
      position: relative;
      transition: all 300ms ease;

      /* &:hover:after {
        content: '';
        display: block;
        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;
        background-color: rgba(190, 190, 190, .3);
        z-index: -1;
        pointer-events: none;
      } */

      .borders {
        width: 100%;
        border-left: 1px solid lighten(@gray-base, 68%);
        border-right: 1px solid lighten(@gray-base, 68%);
        //padding-top: @padding-base-vertical;
      }

      img {
        display: block;
        margin: 0 auto;
        padding-top: @padding-base-vertical;
        padding-bottom: @padding-base-vertical*2;
      }

      span {
        margin-left: @padding-large-vertical*2;
        margin-right: @padding-large-vertical*2;
      }
    }
  }
  .features {
    margin-top: @padding-large-vertical*8;
    margin-bottom: @padding-large-vertical*2;

    .label {
      font-size: @font-size-h6 - 1px;
      color: @gray-dark;
      font-weight: bold;
      text-transform: uppercase;
      display: inline-block;
      padding: @padding-large-vertical @padding-small-horizontal;
      margin-bottom: @padding-large-vertical;
      margin-right: @padding-base-horizontal;
      border-radius: @padding-base-horizontal*2;
    }
  }
  .related {
    background-color: white;
    padding-top: @padding-large-vertical*8;
    padding-bottom: @padding-large-vertical*11;

    .related-title {
      font-size: @font-size-base*2;
      color: @gray;
      margin-bottom: @padding-large-vertical*3;
    }

    .price-rent, .price-sale {
      padding-left: @padding-base-horizontal*2;
      padding-right: @padding-base-horizontal*2;
    }
  }
  .modal {
    .btn-default {
      text-transform: uppercase;

      .glyphicon {top: 2px;}
    }
    .btn-yellow-pos {
      margin-top: 0;
      line-height: @line-height-computed*1.7;
    }
  }

  #galleryModal {
    .close {
      position: absolute;
      color: @brand-danger;
      top: 0rem;
      right: 2rem;

      &,
      &:hover,
      &:focus {
        opacity: 1;
      }

      span {
        font-size: 8rem;
        line-height: 8rem;
      }
    }
  }

  .modal-backdrop.in {opacity: 1;cursor: pointer;}
}

@media screen and (max-width: @screen-sm-max) {
  .estate {
    .aside {
      .metrics {
        padding-top: @padding-large-vertical*4;

        .left {
          margin-bottom: @padding-large-vertical*2;
        }
        .flex-container {
          flex-direction: column;
          //flex-wrap: nowrap;
          //justify-content: space-between;
          //align-items: center;
          //align-content: stretch;
        }
      }
      .button {
        padding-left: @padding-small-horizontal*2;
        padding-right: @padding-small-horizontal*2;

        button.btn {
          margin-top: 0;
        }
      }
      .broker {
        .contact-title {
          margin-top: @padding-large-vertical*2;
          text-align: center;
        }
      }
    }
    .tab-content {
      a {
        display: block;
      }
    }
  }
}

@media screen and (min-width: @screen-md-min) {
  // .aside {margin-top: -7.5%;}
  .estate .aside .metrics .flex-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: stretch;

    .flex-item:nth-child(1) {
      order: 0;
      flex: 0 1 auto;
      align-self: flex-start;
    }

    .flex-item:nth-child(2) {
      order: 0;
      flex: 0 1 auto;
      align-self: center;
      text-align: center;
    }

    .flex-item:nth-child(3) {
      order: 0;
      flex: 0 1 auto;
      align-self: flex-end;
    }
    .flex-item:nth-child(1) {margin-bottom: @padding-large-vertical*2.5;}
    .flex-item:nth-child(2) {margin-bottom: @padding-base-vertical*3;}
    .flex-item:nth-child(3) {margin-bottom: @padding-base-vertical*3;}
  }
  .metrics {
    .left, .right {border-bottom: 1px solid #9a8479;}
    .left {border-right: 1px solid #9a8479;}
  }
}

#gallery-floorplans {
  // .slick-list {
  //   min-height: 96px;
  // }
  img {
    max-width: 98%;
  }
}
