.listing {
  .section-heading {
    margin-top: 0;
    margin-bottom: 0;
  }
  .none-found { margin-top: 2rem; }
  .top-sales .none-found { color: @gray-light; }
  &.all .sort .dropdown {
    margin-top: 0;
    margin-bottom: @padding-large-vertical*3;
  }
  .main {
    > [class*="container"] {
      margin-bottom: @padding-large-vertical*10;
    }
    .tile {
      margin-bottom: @padding-large-vertical*5;
    }
  }
  .main .check-all {
    margin-top: 0;
  }
  .tile.solid {
    background-color: white;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.17);

    .price-rent, .price-sale {
      padding-left: @padding-base-horizontal*2;
      padding-right: @padding-base-horizontal*2;
    }
  }
  .inline-slider,
  .offers-slider {margin-bottom: 0;}
  .inline-slider-nav {
    .slick-arrow {
      width: unset;
      height: unset;
    }
    button {
      padding:0; margin:0;border:0;background-color:transparent;
      img {height: 52px;}
    }
  }
  .top-sales {
    ul {
      text-align: right;

      li {

        &:first-child {text-align: right;}
        width: unset;
        float: none;
      }
    }
    .suffix {
      font-size: 60%;
    }
  }
}

.listing.all {
  .section-heading {
    margin-bottom: @padding-large-vertical*5;
  }
}
