.sidebar {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .category {
    > .title {
      background-color: @gray-dark;
      background-repeat: no-repeat;
      background-position: @padding-large-vertical*1.3 center;
      padding: @padding-large-vertical*2;
      padding-left: @padding-small-horizontal*5.3;
      color: @brand-yellow;
      line-height: 1;
      font-family: @font-family-serif;
      font-size: @font-size-base*1.14285714285714;
      font-weight: bold;
      text-transform: uppercase;
    }

    .subcategories {
      > .title {
        background-color: rgba(red(@gray-dark), green(@gray-dark), blue(@gray-dark), 0.06);
        border-bottom: 1px solid lighten(@gray-base, 74%);

        a {
          display: block;
          padding: @padding-large-vertical*2 @padding-large-horizontal;
          color: lighten(@gray-base, 16%);
          font-family: @font-family-serif;
          font-size: @font-size-base*0.928571428571429;
          text-transform: uppercase;
          text-decoration: none !important;

          &:before {
            content: url('/img/bullet.png');
            margin-right: @padding-base-horizontal;
          }
        }
      }
    }
  }
}