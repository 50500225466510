@media screen {
  .filmstrip-hero img,
  .floorplans-for-print {
    display: none;
  }
}
@media print {
  .estate {

    // from bootstrap's print.less
    *,
    *:before,
    *:after {
        background: transparent;
        color: #000; // Black prints faster: h5bp.com/s
        box-shadow: none;
        text-shadow: none;
    }

    .main-navbar .container-adaptive,
    .breadcrumbs,
    .socials,
    .aside .button,
    .nav-tabs,
    .related,
    #footer,
    .suggest-property,
    .back-to-top {
      display: none !important;
    }

    .main-navbar {
      min-height: unset;

      .header-info {
        margin-left: -3rem;
        margin-bottom: 0;

        .container,
        [class*="col-"] { margin: 0; }
        .head-contact {
          margin-left: 1rem;
          margin-right: 0;

          a.glyphicon:before {
            position: relative;
            top: 3px;
            padding: 0;
          }
        }
      }
    }
    .filmstrip-hero {
      a {
        background-image: none;
        height: unset;
      }
      img {
        display: block;
        .img-responsive;
      }
    }
    .main-content {
      margin-top: 2rem;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
      // width: 100%;

      .main { width: 60%; }
      .aside { width: 40%; }

      .main {
        float: left;

        .inline-title {
          font-size: 2.6rem;
          font-weight: bold;
          color: black;
        }
        ul li {
          font-size: 1.6rem;
          line-height: 1.2;
        }
      }
      .aside {
        float: left;
        border-left: 2px solid @gray-lighter;

        [class*="col-"],
        [class*="container"],
        .metrics,
        .button {
          padding-left: 0;
          padding-right: 0;
          margin-left: 0;
          margin-right: 0;
          margin: 0;
          padding: 0;
          box-shadow: none;
        }

        .metrics:first-child {
          [class*="col-"] {
            float: left;
            width: 50%;
          }
        }
        .broker {
          padding: 48px 2rem;
          margin: 1rem 0 0 0;
          box-shadow: none;

          [class*="container"] {
            [class*="col-"] { float: left; }
            [class*="-3"] { width: 30%;}
            [class*="-3"] { padding-top: 1rem;  }
            [class*="-9"] { width: 75%; }
            [class*="-9"] { padding-left: 2rem; }
          }

          .contact-title {
            font-size: 1.4rem;
          }
        }
      }
    }
    .tabbed {
      padding-top: 2rem;
      border-top: 1px solid @gray;
      margin-top: 1rem;

      .tab-pane {
        line-height: 1.1;
        font-size: 1.4rem;
      }

      .floorplans-for-print {
        display: block;
        padding-top: 2rem;
        border-top: 1px solid @gray;
        margin-top: 2rem;
        .clearfix;

        img {
          max-width: 50%;
          display: block;
          float: right;
          padding: 0;
          margin: 0;

          & + & {
            margin-top: 3rem;
          }
        }
      }
    }
    #socket p {
      color: @gray !important;
      opacity: 1;
      text-align: center;
    }
  }
}
