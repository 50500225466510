// compensate for hanging submit button
// button height + 1px border top+bottom + container bottom padding
@bottom-padding: -(@font-size-base*4.71428571428571)-2px;

// position the search panel
.jumbo {
  // position: relative;
  // z-index: 9;

  .search-panel {
    position: static;

    @media screen and (min-width: 1600px) {
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -645px;
      // transform: translateX(-50%);
    }
  }
}

/* saf3+, chrome1+ */
_::-moz-svg-foreign-content, :root .jumbo .search-panel {
  @media screen and (min-width: @screen-md-min) {
    position: absolute !important;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
  }
}

// style the search panel
.search-panel {
  background-color: rgba(44, 44, 44, 0.9);
  //padding-top: @padding-large-vertical*2;
  //margin-bottom: @padding-large-vertical*2;

  // get rid of active outlines
  a, button {outline: 0 !important;}

  @media screen and (min-width: @screen-md-min) {
    #main-form-wrapper {
      padding: 1rem 6rem;
    }
    [class*="collapseControl"] {
      margin-top: 2rem;
    }
  }

  // make inputs fill comments
  .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) { width: 100%; }

  // dropdown labels
  label.for-select {
    margin-top: @padding-large-vertical;
    display: block;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
  }

  // dropdowns
  // trigger
  .dropdown-toggle,
  .dropdown-toggle.disabled:hover {
    color: white;
    background-color: transparent;
    border: 2px solid darken(@brand-yellow, 10%);
    border-radius: 0;
    outline: none !important;
    box-shadow: none;

    // behavior styles
    &:active, &:active:focus {
      color: @brand-gray;
      background-color: darken(@brand-yellow, 5%);
    }
    &:active:focus {
      box-shadow: inset 0 3px 5px rgba(0,0,0,0.125) !important;
    }
  }
  // menu styles
  .dropdown-menu {
    padding:0;

    .inner {
      color:white;
      border: 2px solid darken(@brand-yellow, 10%);
      background:white;
    }
  }
  // behavior styles
  .open {
    .dropdown-toggle {
      &, &:active, &:hover {
        color: @brand-gray;
        box-shadow: none;
        background-color: darken(@brand-yellow, 5%);
      }
    }
  }

  // checkboxes
  .checkbox-inline {
    padding: 0;
    margin-top: 0;
    box-shadow: none;
    line-height: (@line-height-computed + (@padding-base-vertical * 2) + 2);

    input {
      margin: 0 .5rem 0 0;
      position: relative;
      display: none;
    }
  }
  .bootstrap-checkbox {
    // icon
    .btn-link {
      color: @brand-yellow;
      padding-top: 0;
    }
    // text
    + span {
      font-size: @font-size-small;
      font-weight: bold;
      text-transform: uppercase;
      color: white;
      .noselect;
    }
  }

  .form-control[type="text"],
  .form-control[type="number"] {
    border: 2px solid darken(@brand-yellow, 10%);
    height: (@line-height-computed + (@padding-base-vertical * 2) + 4);
  }

  .search-checkboxes {
    // margin-top: @padding-large-vertical*3;

    [class*="col-"] {
      &:first-child {
        padding-right: 0;

        .checkbox-inline {border-right: 0}
      }
      &:last-child {
        padding-left: 0;
      }
    }
  }

  // range selectors
  .slider-container {
    margin-top: 3rem;
    margin-bottom: 1rem;

    h3 {
      margin: 0;
      font-weight:bold;
      text-transform:uppercase;
      color:white;
      line-height: (@line-height-computed + (@padding-base-vertical * 2) + 4)
    }

    // input[type="text"] {
    //   display: inline-block;
    //   width: auto;
    //   max-width: 40%;
    // }
  }

  .slider-container + .slider-container {margin-top: 1rem;}

  .slider-range-wrapper {
    margin: 0 auto;

    .ui-slider {
      margin:@padding-large-vertical 0;
      width: 100%;
      height: @padding-xs-vertical*3;
      background: @gray-lighter;

      .ui-slider-range {
        height: @padding-large-vertical/2;
        background: @brand-yellow;
        margin-top: -@padding-xs-vertical;
      }
      .ui-slider-handle {
        margin-top: @padding-xs-vertical;
        border:none;
        border-radius:@border-radius-base*2.5;
        height:@padding-large-vertical;
        width:@padding-small-horizontal;
        background:@brand-yellow;
      }
      .ui-slider-handle:focus {
        outline:none;
      }
    }

    // left side value shown
    .min-wrapper {
      float: left;
      div {text-align: left;}
    }
    // right side value shown
    .max-wrapper {
      float: right;
      div {text-align: right;}
    }

    .min-wrapper, .max-wrapper {
      max-width: 50%;

      // actual numbers and letters
      span {
        font-size: @font-size-base*1.14285714285714;
        font-weight: bold;
        color: rgba(255,255,255,0.6);
      }
    }
  }

  // collapse trigger
  .collapseTrigger {
    background: 0;
    border: 0;
    padding: 0;
    color: rgba(255,255,255,0.6);
    font-size: @font-size-small;
    font-family: @font-family-sans-serif;
    line-height: (@line-height-computed + (@padding-base-vertical * 2) + 4);

    .text {border-bottom: 1px dotted rgba(255,255,255,0.6);}

    .glyphicon {
      font-size: 60%;
    }
  }

  // submit
  [type="submit"] {
    border-radius: 0;
    line-height: (@line-height-computed + (@padding-base-vertical * 2) + 4);
    font-weight:bold;
    text-transform:uppercase;
    box-shadow: none;
    border: 0;
    margin-top: 0;
  }
}
