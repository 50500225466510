.main-navbar {
	margin:0;
	border:none;
	z-index:99;
	@media screen and (max-width: @screen-md-max) {min-height: unset;}

  .header-info {
      background: #2d2d2d;
      color: #ababab;
  }
  a.glyphicon:before {
    position: relative;
    top: 3px;
  }

  a.glyphicon.glyphicon-envelope {
      text-decoration: none;
      color: #ababab;
  }

  a.glyphicon.glyphicon-earphone {
      text-decoration: none;
      color: #ababab;
  }

  .head-contact {
      font-family: Verdana;
      font-size: 12px;
      font-weight: bold;
      padding: 0;
      margin: 0;
  }
  @media screen {
    .header-info .col-sm-2 + .col-sm-2::before {
        content: "|";
        margin-right: 29px;
    }
    .head-contact {
        padding-left: 16px;
    }
    a.glyphicon:before {
      color: white;
    }
  }


	.container-adaptive {
		@media screen and (max-width: @screen-md-max) {
		  .container-fluid;
		}
		@media screen and (min-width: @screen-lg-min) {
			padding-top: @navbar-padding;
		  .container;
		}
	}

	.navbar-collapse.collapse.in {
		.navbar-right li a {
				border-bottom: 0;
				padding-left: @caret-width-base*2;
				margin: 0 0 0 -@caret-width-base*2;
			&.active {
				padding-left: @caret-width-base*3;
				border-left: @caret-width-base*3 solid @brand-yellow;
			}
			&:hover {
				border-right: @caret-width-base*3 solid @brand-yellow;
			}
		}
		//.lang-wrapper {display: none;}
		/* // language
		// trigger
		.dropdown-toggle {
			//line-height: @navbar-subheight;
			//padding: 0px;
			.navbar-vertical-align(68px);

			img { vertical-align: middle; }
			.caret { vertical-align: middle; }
		}
		// menu
		.dropdown-menu {
			min-width: unset;
			left: -4px;

			a { padding: 4px; }
		} */
	}

	// navbar links - standardize heights
	.navbar-nav > li > * {
		line-height: @navbar-subheight;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
	@media screen and (max-width: @screen-md-max) {
		.navbar-nav {
			margin: 0;
			a { line-height: 40px; }
		}
		.lang-wrapper {

			img { vertical-align: middle; }
			.caret { vertical-align: middle; }

			.dropdown-menu {
				position: absolute;
				background-color: @gray-lighter;
				padding: 0;
				min-width: auto;
				left: -@padding-xs-horizontal*3;
				margin-top: @padding-xs-horizontal*3+1px;

				> li > a {
					padding: 0 @padding-xs-horizontal*3;
					margin-top: @padding-large-vertical;
					margin-bottom: @padding-large-vertical;
				}
			}
		}
		#search-menu {
			left: unset;
			right: unset;
			height: auto;
			line-height: unset;

			.dynamicSearchInput {
				top: unset;
				left: unset;
				bottom: unset;
				right: unset;
				width: 100%;
			}

			@media screen and (min-width: @screen-sm-min) {
			  .navbar-vertical-align(68px);
			  left: unset;
			  right: 55px;
			}
		}
	}

	// branding
	.navbar-brand {
    padding: 10px 15px 10px;
		height: @navbar-subheight;
    // @media screen and (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    //   padding: 10px 15px 0px;
    // }
    @media screen and (min-width: @screen-lg-min) {
      padding: 0 @navbar-padding-horizontal @logo-padding;
      height: 82px;
      margin-top: -14px;
    }

		img {
			max-height: 100%;
			height:inherit;
			line-height:inherit;
		}
	}

	.navbar-toggle {
		//margin-top: ;
		//margin-bottom: ;
		.navbar-vertical-align(68px);
	}

	// language
  #lang-wrapper-inline { // XS + SM
    float: right;
    margin: 6px 12px 8px;
  }
  #lang-wrapper { // MD + LG
    float: left;
    @media screen and (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      margin-top: 16px;
    }
    // .navbar-vertical-align(68px);
  }
	// trigger
	.dropdown-toggle {
		line-height: @navbar-subheight;
		padding: 0px;

		img { vertical-align: -18px; }
		.caret { vertical-align: -4px; }
	}
	// menu
	.dropdown-menu {
		min-width: unset;
		left: -@caret-width-base;

		a { padding: 4px; }
	}

	// nav menu
	.navbar-right li a {
		margin: 0 @padding-base-horizontal;
		padding: @padding-large-vertical*2 0;
		line-height: @navbar-subheight - @caret-width-base*2;
    @media screen and (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      padding: 6px 0 0 !important;
      line-height: 54px;
    }
		font-weight: bold;
		color: @gray-darker !important;
		text-transform: uppercase;
		border-bottom: @caret-width-base*2 solid transparent;
		transition: all 150ms ease;
	}
	// behavior styles
	.navbar-right li a.active,
	.navbar-right li a:hover,
	.navbar-right li a:active {
		border-bottom: @caret-width-base*2 solid @brand-yellow;
	}

	// search
	.search-menu {
		margin-top: @caret-width-base*2;
		left: 0;
		width: @search-icon;
		height: @search-icon;

		input {
			position: absolute;
			right: 0;
			width: 0;
			padding: @padding-large-vertical @padding-small-horizontal*1.5;
			line-height: @padding-large-vertical*2;
			color: @gray-darker;
			border-radius: 0;
			background: transparent url("../img/search-icon.png") no-repeat 95% center;
			border:none;
			border-radius: @border-radius-base;
			transition: all 300ms ease;

			&::-webkit-input-placeholder {color:@gray-darker !important;}
			&:-moz-placeholder {color: @gray-darker !important;}
			&::-moz-placeholder {color: @gray-darker !important;}
			&:-ms-input-placeholder {color: @gray-darker !important;}

			&:focus {
				outline: none;
				width:@search-width;
				border:1px solid @brand-yellow;
				background-color: white;
			}
		}

		@media screen and (max-width: @screen-md-max) {
			.navbar-vertical-align(68px);
			left: unset;
			right: 80px;
			position: absolute;
			width: auto;

			input {
				position: static;
				outline: none;
				width: 300px;
				border: 1px solid @brand-yellow;
				background-color: white;
		    line-height: @line-height-computed*1.2;
		    padding-top: @padding-xs-vertical*4;
		    padding-bottom: @padding-xs-vertical*4;
			}
		}
	}
}

// search dropdown
#search-menu.search-menu {
	position: relative;

	.search-ac {
	  display: none;
	  position: absolute;
	  top: @navbar-subheight - @caret-width-base*2;
	  right: 0;
	  z-index: 10;
	  background: @brand-white;
	  border: 1px solid;
	  border-color: lighten(@brand-yellow, 20%) lighten(@brand-yellow, 30%) lighten(@brand-yellow, 40%);
	  border-radius: @border-radius-small;
	  padding-left: 0;

	  li {
	    display: block;

	    &:first-child {
	      margin-top: -1px;

	      &:before, &:after {
	        content: '';
	        display: block;
	        width: 0;
	        height: 0;
	        position: absolute;
	        left: 99%;
	        margin-left: -@padding-xs-horizontal;
	        border: 5px outset transparent;
	      }

	      &:before {
	        border-bottom: 5px solid #c4c7d7;
	        top: -11px;
	      }

	      &:after {
	        border-bottom: 5px solid #fdfdfd;
	        top: -10px;
	      }

	      &:hover { &:before, &:after { display: none; } }
	    }

	    &:last-child { margin-bottom: -1px; }
	  }

	  a {
	    display: block;
	    position: relative;
	    line-height: 1;
	    margin: 0 -1px;
	    padding: @padding-base-vertical @padding-base-horizontal;
	    color: @gray;
	    font-weight: 500;
	    text-decoration: none;
	    text-shadow: 0 1px white;
	    border: 1px solid transparent;
	    border-radius: @border-radius-small;

	    span { font-weight: 200; }

	    &:hover {
	      color: white;
	      background: @brand-yellow;
	      border-color: darken(@brand-yellow, 10%) darken(@brand-yellow, 15%) darken(@brand-yellow, 25%);
	    }
	  }
	}
}

#search-menu.search-menu {
	.tableplz {display: table;}
	.cellplz {display: table-cell; vertical-align: top;}
	ul {width: 640px;}
	h5 {margin: 0;}

	.cellplz:nth-child(1) {
		text-align: left;
		width: 12%;
	}
	.cellplz:nth-child(2) {
		width: 63%;
		padding: 0 @padding-small-horizontal;
	}
	.cellplz:nth-child(3) {
		text-align: right;
		width: 25%;
	}

	.search-ac {
		&.scrollbar {
			max-height: @navsearch-row-height*5;
			overflow-x: hidden;
			overflow-y: scroll;
		}
		li a {min-height: @navsearch-row-height;}
		li:hover .prop-location {color: darken(@brand-yellow, 30%)}
	}
	.prop-img {width: 100%;}
	.prop-name {
		margin-bottom: @padding-base-vertical*2;
	}
	.prop-location {
		text-transform: none;
		text-shadow: none;
	}
	.prop-price {
		font-weight: bold;
		text-transform: none;

		.price-rent {font-size: 1.8rem;}
		.price-sale {font-size: 2.4rem; margin-bottom: @padding-base-vertical*2;}
	}
}
