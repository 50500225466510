.about {
  .section-heading {
    padding-bottom: @padding-base-vertical*3;
    margin-bottom: 0;
  }
  .about-texts,
  .team-brief {
    p {
      font-family: @font-family-serif;
      font-size: @font-size-largish;
      color: @gray-dark;

      &.lead { font-weight: bold; }
    }
  }
  .inner-title {
    font-family: @font-family-serif;
    font-size: @font-size-base*1.57142857142857;
    line-height: 1.4;
    text-transform: uppercase;
    padding-bottom: @padding-large-vertical*2;
    margin-top: 0;
    margin-bottom: @padding-large-vertical*2;
    border-bottom: 1px solid @gray;
  }
  .about-texts {
    margin-top: @padding-base-vertical*8;
  }
  .team-brief {
    margin-top: @padding-base-vertical*8;
    margin-bottom: @padding-large-vertical*16;
  }
  .team-members {
    padding-bottom: @padding-large-vertical*21;

    .person-photo {
      border-radius: 50%;
      border: 10px solid @brand-yellow;
      margin-top: @padding-large-vertical*8;
    }
    .person-name {
      font-family: @font-family-headings;
      font-size: @font-size-h2;
      font-weight: bold;
      color: @brand-gray;
      text-transform: uppercase;
      margin-top: @padding-large-vertical*4;
      margin-bottom: 0;
    }
    .person-title {
      font-family: @font-family-serif;
      font-size: @font-size-h4;
      color: @gray-light;
      text-transform: uppercase;
      margin-top: @padding-large-vertical*3;
      margin-bottom: 0;
    }
  }
}