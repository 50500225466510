#footer {
  padding:@padding-large-vertical*7 0 @padding-large-vertical*2;
  background-color: lighten(@gray-base, 13.5%);
  font-family: @font-family-serif;

  .title {
    margin:-@padding-large-vertical*5 0 @padding-large-vertical*1.5;
    font-size:@font-size-base*2.28571428571429;
    font-weight:300;
    line-height:@line-height-computed*1.75;
    color:white;
    @media screen and (max-width: @screen-sm-max) {
      display: none;
    }
  }
  .subtitle {
    margin:0 0 @padding-large-vertical 0;
    text-transform:uppercase;
    font-size:@font-size-base*0.928571428571429;
    font-weight:bold;
    color:@brand-yellow;
    opacity: .4;
  }
  ul {
    padding: 0;
    list-style: none;
  }
  a,
  p {
    font-size:@font-size-base*0.928571428571429;
    font-weight:300;
    line-height:@line-height-computed*1.15;
    color:white;
    opacity: .7;
    text-decoration: none;
  }
  p {margin-bottom: 0;}
  a {transition: all .25s ease-in-out;}

  #suggest {
    margin:@padding-large-vertical*2 0 0 0;
    display:inline-block;
    font-weight:bold;
    font-size:@font-size-base*0.928571428571429;
    text-transform:uppercase;
    color:white;
    opacity: .7;
    text-decoration: none;
  }
  a:hover,
  #suggest:hover {
    opacity: 1;
  }

  .social-icons {
    margin-top: @padding-large-vertical*2;
    .text-left;
    a {
      margin-right:@padding-xs-horizontal;
      margin-left:0;
    }
    @media screen and (min-width: @screen-md-min) {
      .text-right;
      a {
        margin-left:@padding-xs-horizontal;
        margin-right:0;
      }
    }
    a:hover {opacity:0.7;}
  }
}

#socket {
  border-top:1px solid @brand-gray;
  padding:@padding-large-vertical 0;
  background:lighten(@gray-base, 13.5%);
  font-size:@font-size-base*0.928571428571429;
  font-family: @font-family-serif;

  p {
    margin-bottom: 0;
    opacity: .14;

    &,
    a {
      color:white;
    }
  }

  #chat-icon {
    position: fixed;
    bottom: 0;
    z-index:9;
  }
}
