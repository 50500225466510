.recommended {
  padding:@padding-large-vertical*6 0 @padding-large-vertical*6 0;
  @media screen and (min-width: @screen-md-min) {padding:@padding-large-vertical*18.5 0 @padding-large-vertical*6 0;}
  background:@brand-gray;

  .recommended-text-wrapper {
    text-decoration: none;
    display: block;
    margin-bottom: @padding-large-vertical*4;

    .title {
      margin-bottom:@padding-large-vertical*3;
      font-weight:bold;
      text-transform:uppercase;
      font-size:@font-size-base*2.14285714286;
      line-height:@line-height-computed*2.25;
      color:@brand-yellow;
      letter-spacing: -2px;
    }

    .additional-info {
      color:lighten(@gray-base, 72%);
      font-size:@font-size-base;
      line-height:@line-height-computed;
    }
  }

  .price-rent, .price-sale {
    padding-left: @padding-base-horizontal*2;
    padding-right: @padding-base-horizontal*2;
  }
}
