.error-page {
  .section-heading {
    margin-top: @padding-large-vertical*6.5;
  }

  #footer,
  #socket {
    .navbar-fixed-bottom;
  }

  #footer {
    padding-bottom: 60px;
  }
}
