.categories {
  padding:@padding-large-vertical*6 0 @padding-large-vertical*6 0;
  @media screen and (min-width: @screen-md-min) {padding:@padding-large-vertical*7 0 @padding-large-vertical*12 0;}

  .single-category-wrapper {
    margin-bottom: @padding-large-vertical*6;

    .title {
      white-space: unset;
      letter-spacing: -4px;
      line-height: 40px;
      min-height: 80px;
      font-family: @font-family-serif;
      font-weight: 300;
    }

    @media screen and (min-width: @screen-md-min) {
      .title {
        // white-space:nowrap;
        letter-spacing: -2px;
      }
    }

    ul {
      max-width: 85%;
      margin-left: auto;
      margin-right: auto;
      display:block;
    }
  }

  .title {
    margin: 0 0 @padding-large-vertical*2.5 0;
    font-weight: 300;
    font-size: @font-size-base*2.85714285714286;
    text-align:center;
    color:lighten(@gray-base, 72%);
  }

  ul {
    padding: 0;
    list-style: none;

    li a {
      display:block;
      padding: @padding-large-vertical*1.2 0 @padding-large-vertical*0.8 0;
      border-bottom: 1px solid lighten(@gray-base, 27%);
      color: lighten(@gray-base, 27%);
      transition: all .15s ease-out;
      text-decoration: none;

      &:hover {
        color: darken(@brand-yellow, 15%);
        border-bottom: 1px solid darken(@brand-yellow, 15%);
      }
    }
  }
}
