.circles {
  .circle {
    img {
      margin-left: auto;
      margin-right: auto;
      display:block;
    }
    img {
      width:100%;
      margin: 0 0 @padding-large-vertical*6 0;
      border-radius: 50%;
      border: 10px solid @brand-yellow;
      transition: all .3s ease;
    }
  }
}

_::-moz-svg-foreign-content, :root .circle {

}

_::-moz-svg-foreign-content, :root .circle {
  border-radius: 50%;
  border: 10px solid @brand-yellow;
  width: 300px;
  margin: 0 auto @padding-large-vertical*6;

  img {
    border: none;
    margin: 0;
    width: auto !important;
    max-width: none !important;
    // width:unset;
    // max-width:unset;
  }
}
