.slider {margin-bottom: 0;}

@media screen and (max-width: @grid-float-breakpoint) {
  div.jumbo {margin-bottom: 0;}
}

// the content is actually in this one
.slider .center {
  .container; // watch out for the nav arrows
  //height: 50%; // use to manually adjust vertical position
  // transform: translate(-50%, -100%);
  // padding-bottom: 3%;
  // top: unset;
  // bottom: 12.5%;
}

img.b-lazy { max-width: unset; }

.slide-title {
  font-size: @slider-title-size;
  font-weight: bold;
  color: @brand-white;
  text-transform: uppercase;
  white-space: nowrap;
  text-shadow: 0px 4px 10px rgba(0,0,0,0.33);
  margin-top: 0;
}

.slide-subtitle {
  font-family: @font-family-headings;
  font-size: @slider-subtitle-size;
  font-style: italic;
  color: @brand-white;
  text-shadow: 0px 4px 10px rgba(0,0,0,0.33);
}

@media screen and (max-width: @screen-sm-max) {
  .slide-title {
    font-size: 5rem;
  }
  .slider .center {
    top: 50%;
    bottom: unset;
    // transform: translate(-50%, -50%) scale(0.5);
    padding-left: @padding-small-horizontal*8;
    padding-right: @padding-small-horizontal*8;
  }
}

.slick-prev {
  left: 5%;
}

.slick-next {
  right: 5%;
}

.slick-arrow {
  z-index: 1;
  width: auto;
  height: auto;
  &:before, &:after {content: unset; display: none;}

  img { width: 20px; }
  @media screen and (min-width: @screen-sm-min) {
    img { width: 60px; }
  }

  @media screen and (min-width: 1600px) {
    transform: translate(-50%, -200%);
  }
}

.slider .center {
  padding-bottom: 20px;

  @media screen and (max-width: @screen-xs-max) {
    transform: translate(-50%, -50%) scale(0.5);
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    transform: translate(-50%, -50%) scale(0.75);
  }
  // @media screen and (min-width: @screen-md-min) and (max-width: @screen-md-max) {
  //   transform: translate(-50%, -100%) scale(0.5);
  // }
  @media screen and (min-width: @screen-md-min) and (max-width: 1400px) {
    transform: translate(-50%, -50%);
    top: 50%;
    bottom: unset;
  }
  @media screen and (min-width: 1600px) {
    transform: translate(-50%, -100%);
    top: 50%;
    bottom: unset;
  }
}

.slick-slide img {
  max-width: 100%;
}
