#estate-filmstrip-gallery,
#estate-filmstrip-gallery-nav {
  display: none;
  margin-bottom: 0;
  .slick-arrow {
    transform: initial;
    img {
      margin-top: -50%;
    }
  }
}
#estate-filmstrip-gallery.slick-initialized,
#estate-filmstrip-gallery-nav.slick-initialized {
 display: block;
}
#estate-filmstrip-gallery {
	.slick-dots {
		bottom: 0;
		margin-bottom: 0;
		li {
			button:before {
				color: white;
				opacity: .5;
				text-shadow: 1px 1px black;
			}
		}
		li.slick-active {
			button:before {
				opacity: 1;
				text-shadow: 0px 0px 1px black;
			}
		}
	}
  .slick-arrow {
    img {
      width: 25px;
    }
  }
}
#estate-filmstrip-gallery-nav {

  .slick-arrow {
    img {
      width: 15px;
    }
  }
}